<script>
import ui from '/~/core/ui'
import modal from '/~/core/mdl'
import BaseMdl from '/~/components/mdl/mdl-popup.vue'
import debounce from 'lodash-es/debounce'
import BaseInput from '/~/components/base/input/base-input.vue'
import BaseButton from '/~/components/base/button/base-button.vue'
import UsersList from '/~rec/components/users/users-list.vue'
import UsersSelectedMobile from '/~rec/components/users/users-selected-mobile.vue'
import HeaderMobile from '/~rec/components/general/header-mobile.vue'
import { useChat } from '/~rec/composables/chat'

export default {
  name: 'rec-chat-create',
  components: {
    BaseMdl,
    HeaderMobile,
    UsersList,
    BaseInput,
    UsersSelectedMobile,
    BaseButton,
  },
  setup() {
    const { createRoom, openRoom } = useChat()

    return {
      createRoom,
      openRoom,
      ui,
    }
  },
  data() {
    return {
      searchValue: '',
      selectedUsers: [],
      processing: false,
    }
  },
  methods: {
    onUserSelect(u) {
      this.selectedUsers = u
    },
    onUserUnselect(user) {
      const idx = this.selectedUsers.indexOf(user)

      this.selectedUsers.splice(idx, 1)
    },
    back() {
      modal.hide()
      this.$emit('back')
    },
    onSearchInput: debounce(function (searchValue) {
      this.searchValue = searchValue
    }, 150),
    onGroupAdd() {
      modal.show('rec-groups-manager', {
        props: {
          title: 'Add all members from',
          features: 'members',
          onlyMyGroups: true,
        },
        on: {
          save: (users) => {
            users.forEach((user) => {
              const exist = this.selectedUsers.find((selectedUser) => {
                return selectedUser.id === user.id
              })

              if (!exist) {
                this.selectedUsers.push(user)
              }
            })

            this.onSave()
          },
        },
      })
    },
    async onSave() {
      if (this.selectedUsers.length > 1) {
        modal.show('rec-chat-settings', {
          props: {
            title: 'New Chat',
            users: this.selectedUsers,
          },
          on: {
            save: async (newRoom) => {
              modal.hideAll()
              this.openRoom(newRoom.id)
            },
          },
        })
      } else {
        try {
          this.processing = true
          const newRoom = await this.createRoom({
            userIds: this.selectedUsers.map((user) => user.id),
          })

          modal.hide()
          this.openRoom(newRoom.id)
        } catch (e) {
          console.error(e)
        } finally {
          this.processing = false
        }
      }
    },
  },
}
</script>

<template>
  <base-mdl
    fullscreen="mobile"
    width="sm"
    height="lg"
    title="New Chat"
    modal-class="pb-[88px] relative"
  >
    <template #header>
      <div v-if="ui.mobile">
        <header-mobile title="New Chat" @back="back" />
      </div>
    </template>

    <div class="flex items-center text-base leading-6 text-eonx-neutral-600">
      <span>Select users for the chat</span>
      <base-button size="sm" class="ml-auto" @click="onGroupAdd">
        <span class="text-sm">Add a group</span>
      </base-button>
    </div>

    <users-selected-mobile
      exclude-me
      :selected-users="selectedUsers"
      @unselect="onUserUnselect"
    />

    <base-input
      :value="searchValue"
      look="recroom"
      icon="plain/search"
      placeholder="Search member"
      icon-plain
      clearable
      @input="onSearchInput"
    />
    <users-list
      :current-users="selectedUsers"
      :search-value="searchValue"
      :removable="false"
      multi
      exclude-me
      selectable
      @user-select="onUserSelect"
    />
    <div class="absolute bottom-0 right-0 mr-11 bg-white py-5">
      <base-button
        class="ml-auto"
        :disabled="!selectedUsers.length"
        :loading="processing"
        @click="onSave"
      >
        Save
      </base-button>
    </div>
  </base-mdl>
</template>
